import React from "react";
import "./demographyDocumentation.css";

const DemographyDocumentation = () => {
  return (
    <div className="demography-documentation-container">
      <h2>DOCUMENTATION</h2>
      <p>
        Note: These texts are all my personal approach and is subjected to change to fit the actual needs. Also these
        documentation are just a rough sketch, until we find an approach to have them organized in a better way.
      </p>

      <div className="documentation-containers">
        <h3>NEED FOR THE WING</h3>
        <p>
          Note: Before we bring forth a new wing, we must first establish why we need the particular wing. What things
          it will handle? And so, each wing will have this section. Below you will find my reasons for this wing. I will
          add a comment functionality in future so that I can receive your suggestions about the matter.
        </p>

        <div className="paragraph-div">
          <p>
            DEMOGRAPHY WING will be responsible to maintain the country population data. At any point it should be able
            to bring forth a complete detail of an individual. The details can be classified in broad categories as:
          </p>
          <li>
            IDENTITY
            <p>
              Identity detail will provide the details of one's identity. His/her immediate family relations (Parents &
              Children) and will include things like: Father's Name, Mother's Name, Full Name, Date of Birth, Gender,
              Contact Information, Citizenship ID
            </p>
            <p>CITIZENSHIP ID GENERATION:</p>
            <p>CID-BIRTH-DATE-FAMILYID-BIRTHORDER</p>
            <p>CID: Just short for 'CitizenshipID</p>
            <p>
              BIRTH-DATE: We include birth-date so that we can control the misuse of 'Age Factor'. Like a child or a
              teen, when entering some pubs requiring certain age limits, having this date readily available will be
              prime factor. Any pubs, liquor shops, or other things meant for only adults can be then controlled
              effectively. Allowing the ineligible candidates with any facilities or services will immediately bring
              them into jurisdiction if such cases arises. I believe, it can be helpful in many other cases as well.
            </p>
            <p>
              FAMILYID: This is the unique ID given to each marriage registration. This will help us quickly identify
              the ancestral tree of any individual. All we need to do is move up or down with each familyID we find in
              each ID's.
            </p>
            <p>BIRTHORDER: 1. Firstborn 2.Secondborn and so on...</p>
          </li>
          <li>ADDRESS</li>
          <p>
            These details will include, temporary and permanent address details. Anytime, someone changes their
            location, the change is to be immediately reported to 'DEMOGRAPHY WING'. This change report will be handled
            at ward level. Ward from where one is moving out will provide and 'Safe Journey' ticket. And only if one is
            able to provide, this 'Safe Journey' ticket at the next ward, their application for address change will be
            considered valid. Individual failing to update their address details within one month of their migration
            will be subjected to penalties.
          </p>

          <p>We will never know, who is migrating when and thus this will not be effective??</p>

          <p>
            And for this, we will have may be a sub-wing, or a separate wing that deals with Housing thing. The idea is
            since we plan to have each house numbered, the house owner is to notify when someone leaves their room/flat
            or house that was on lease. Then may be we will have someone from ward confirm this by visiting the house
            itself. Once confirmed the status changes to 'Unknown Whereabout'. and if the whereabouts is not known
            within the next month, they will be penalized on their next appearance.
          </p>

          <p>
            By no means, this is done to trouble the individual, its rather for the security of the individual and the
            others. We dont want a criminal to run about freely whereever they want right. Just an example of the thing.
            I hope we wont have any criminals in future.
          </p>
          <p>
            This will also help us in keeping check of Non-Nepalese residents. Anyone who enters the territory, and is
            planning to be here for more than 3 month of time, or if have crossed, since their arrival, we need them to
            be addressed to a specific place. You are free but not invisible to us.
          </p>
          <p>
            If a tourist is to have an excuse such as: I will be travelling a lot, I cannot have a fixed place, even
            then we will have a mechanism to track their whereabouts. But that will be discussed in the Immigration Wing
            I guess.
          </p>
        </div>
        <div className="paragraph-div">
          <h3>VIEW OF DEMOGRAPHY WING</h3>
          <p>
            You can see seven cards in 'DEMOGRAPHY WING. Each card reflect the status of a province. Then within each
            card, we will again have the 'District' list in similar fashion. But not now, for now we are only with the
            Provincial Cards. And if we collectively agree that this is the efficient way to view things then only we
            will proceed to make 'District' cards as well. For now, the card will show the number of residents from each
            ward, municipality, district and province.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DemographyDocumentation;
