// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-wrapper {
  width: 100%;
  height: 100dvh;

  position: relative;
  box-sizing: border-box;
  background-color: black;
  gap: 1rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  overflow: hidden;
}

.site-hazard,
.site-goal,
.login-signup {
  width: 100%;
  height: 100%;

  background: white;

  padding: 1rem 2rem;
  overflow: auto;
}

.site-hazard {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.danger-text {
  color: red;
}

.declaration {
  margin-top: 1.5rem;
}

.declaration p {
  margin-bottom: 1rem;
}

.declaration-bullet-points {
  margin-left: 1rem;
}

.site-goal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

@media (max-width: 42rem) {
  .landing-wrapper {
    grid-template-columns: auto;
    gap: 0.2rem;
    overflow: auto;
  }

  .site-hazard,
  .site-goal,
  .login-signup {
    height: 100%;
    overflow: visible;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/LandingPage/landing.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;;EAEd,kBAAkB;EAClB,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;;EAET,aAAa;EACb,kCAAkC;EAClC,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;;;EAGE,WAAW;EACX,YAAY;;EAEZ,iBAAiB;;EAEjB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE;IACE,2BAA2B;IAC3B,WAAW;IACX,cAAc;EAChB;;EAEA;;;IAGE,YAAY;IACZ,iBAAiB;EACnB;AACF","sourcesContent":[".landing-wrapper {\n  width: 100%;\n  height: 100dvh;\n\n  position: relative;\n  box-sizing: border-box;\n  background-color: black;\n  gap: 1rem;\n\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  grid-template-rows: 1fr;\n  overflow: hidden;\n}\n\n.site-hazard,\n.site-goal,\n.login-signup {\n  width: 100%;\n  height: 100%;\n\n  background: white;\n\n  padding: 1rem 2rem;\n  overflow: auto;\n}\n\n.site-hazard {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: justify;\n}\n\n.danger-text {\n  color: red;\n}\n\n.declaration {\n  margin-top: 1.5rem;\n}\n\n.declaration p {\n  margin-bottom: 1rem;\n}\n\n.declaration-bullet-points {\n  margin-left: 1rem;\n}\n\n.site-goal {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  text-align: center;\n}\n\n@media (max-width: 42rem) {\n  .landing-wrapper {\n    grid-template-columns: auto;\n    gap: 0.2rem;\n    overflow: auto;\n  }\n\n  .site-hazard,\n  .site-goal,\n  .login-signup {\n    height: 100%;\n    overflow: visible;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
