import React, { useState } from "react";
import "./shunyaSignup.css";

import { useNavigate } from "react-router-dom";

const ShunyaSignup = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [dissolvedName, setDissolvedName] = useState("");
  const [dissolutionSpell, setDissolutionSpell] = useState("");

  const navigateToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="wizards-registration">
      <h3>SHUNYA</h3>
      <h1>SIGN UP</h1>

      <p>The realm was waiting for you.....</p>
      <p>And is waiting for other who will join hands in future.</p>

      <div className="email-address">
        <p>You know where to find me. I ought to know your spell as well.</p>
        <p>Can you provide me your digital address...</p>
        <input type="email" />
      </div>

      <div className="username-pick">
        <p>Now that I know where to find you, may I know how should I call you.</p>
        <p>Choose your 'Dissolution Name'.</p>
        <input type="text" />
      </div>

      <div className="access-spell-conjuring">
        <p>Ah... I can already see the name, reaching far and beyond into this realm...</p>
        <p>How about, crafing a spell, one that you will conjure when you need to re-visit these lands...</p>
        <p>
          And mind you!! Don't forget that as soon as you are done providing it. We don't yet have any spell revocation
          spells.
        </p>
        <input type="password" />
      </div>

      <button>DISSOLVE ME</button>
      <button onClick={navigateToLogin}>I HAVE ALREADY BEEN DISSOLVED</button>
      <p>
        Please not, for now there are no mechanism implemented to check for valid email-addresses or the strength of
        password. But since we will use OPT for registration process, do provide a valid email-address so that you can
        receive OTP and then complete registration.
      </p>
    </div>
  );
};

export default ShunyaSignup;
