import axios from "axios";

const BASE_URL =
  process.env.NODE_ENV === "development" ? "https://dev.invoicesxpress.com/api/" : "https://invoicesxpress.com/api/";

// Create a normal axios instance for public API calls
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Create a protected axios instance for authenticated API calls
const axiosProtectedInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

const refreshToken = async () => {
  try {
    await axiosProtectedInstance.post("/refresh-session");
    return true;
  } catch (error) {
    console.error("Error refreshing token", error);
    return false;
  }
};

axiosProtectedInstance.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  } else {
    config.headers["Content-Type"] = "application/json";
  }
  return config;
});

axiosProtectedInstance.interceptors.response.use(
  (response) => response, // Return the response directly if it's successful
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshed = await refreshToken();

        if (refreshed) {
          return axiosProtectedInstance(originalRequest);
        } else {
          return Promise.reject(error);
        }
      } catch (refreshError) {
        console.log("Token refresh failed", refreshError);
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export { axiosInstance, axiosProtectedInstance };
