import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ResponseDialog from "./components/ResponseDialog/ResponseDialog";

import PersistProtectedRoutes from "./Layouts/PersistProtectedRoutes";
// import OTPVerifier from "./Layouts/HomeLayout/Subcomponents/HomeNavbar/Subcomponents/OTPVerifier/OTPVerifier";

import LandingPage from "./Pages/LandingPage/LandingPage";

import DissolutionPortal from "./Pages/DissolutionPortal/DissolutionPortal";
import ShunyaInitiation from "./Pages/ShunyaInitiation/ShunyaInitiation";
import ShunyaSignup from "./Pages/ShunyaSignup/ShunyaSignup";
import ShunyaLogin from "./Pages/ShunyaLogin/ShunyaLogin";

import ShunyaRealm from "./Pages/ShunyaRealm/ShunyaRealm";

function App() {
  return (
    <div className="app-container">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<DissolutionPortal />} /> */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/initiation" element={<ShunyaInitiation />} />
          <Route path="/signup" element={<ShunyaSignup />} />
          <Route path="/login" element={<ShunyaLogin />} />
          <Route path="/shunya-realm/*" element={<ShunyaRealm />} />
        </Routes>
      </BrowserRouter>
      <ResponseDialog />
    </div>
  );
}

export default App;
