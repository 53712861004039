import useAPICall from "../../APICallHooks/useAPICall";
import { useResponse } from "../../../context/ResponseContext";
import { SHUNYA_INITIATION } from "../URLsDefinations/ShunyaInitiationURLs";

const useShunyaInitiation = () => {
  const { executeAPICall } = useAPICall();
  const { setResponseMessage } = useResponse();

  const dissolveIntoShunya = async (shunyaSpell) => {
    const controller = new AbortController();
    const { signal } = controller;

    try {
      const response = await executeAPICall({
        URL: SHUNYA_INITIATION,
        method: "post",
        parameters: { shunyaSpell },
        signal,
      });

      if (response?.success) {
        return true;
      } else {
        setResponseMessage({
          status: "error",
          title: `Shunya's Portal Refused The Spell`,
          message: response?.errorMessage,
        });
        return;
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        setResponseMessage({
          status: "error",
          title: "Portal Initiation Failed",
          message: error.message,
        });
        return;
      }
    } finally {
      controller.abort();
    }
  };

  return { dissolveIntoShunya };
};

export default useShunyaInitiation;
