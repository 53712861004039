import React from "react";
import "./responseDialog.css";
import { useResponse } from "../../context/ResponseContext";

const ResponseDialog = () => {
  const { responseMessage, setResponseMessage } = useResponse();

  if (!responseMessage) return null;

  const { status, title, message } = responseMessage || {};
  return (
    <div className="response-overlay" onClick={() => setResponseMessage(null)}>
      <div className="response-envelope" onClick={(e) => e.stopPropagation()}>
        <h3 className="response-title">{title}</h3>
        <div className="response-content">
          <p className="response-message">{message}</p>
          <button
            className={`response-btn ${status === "success" ? "success" : "error"}`}
            onClick={() => setResponseMessage(null)}
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResponseDialog;
