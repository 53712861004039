import React, { useState } from "react";
import "./demographyWing.css";

import DemographyDocumentation from "./DemographyDocumentation";
import CitizenshipForm from "./CitizenshipForm/CitizenshipForm";

const DemographyWing = () => {
  const [openDemographyDocumentation, setOpenDemographyDocumentation] = useState(false);
  const toggleDocumentationView = () => {
    setOpenDemographyDocumentation((prevState) => !prevState);
  };

  const [openCitizenshipForm, setOpenCitizenshipForm] = useState(true);
  const toggleCitizenshipForm = () => {
    setOpenCitizenshipForm((prevState) => !prevState);
  };
  return (
    <div className="wing-container">
      <div className="wing-title">
        <h1>Demography Wing</h1>
        <button onClick={toggleDocumentationView}>
          {openDemographyDocumentation ? "CLOSE DOCUMENTATION" : "OPEN DOCUMENTATION"}
        </button>
      </div>
      {openDemographyDocumentation ? (
        <DemographyDocumentation />
      ) : (
        <>
          <div className="wing-services">
            <h2>SERVICES</h2>
            <div className="services-list">
              <button onClick={toggleCitizenshipForm}>
                {openCitizenshipForm ? "CLOSE APPLICATION" : "APPLY FOR CITIZENSHIP"}
              </button>
            </div>
            <h3>Total Reach: 0</h3>
          </div>
          {openCitizenshipForm ? (
            <CitizenshipForm />
          ) : (
            <div className="wing-content">
              <div className="provincial-cards">
                <h2>PROVINCE NAME</h2>
                <div className="address-data">
                  <div className="temporary">
                    <h4>Temporary Residents</h4>

                    <p>District:</p>
                    <p>Municipality:</p>
                    <p>Ward:</p>
                  </div>
                  <div className="permanent">
                    <h4>Permanent Residents</h4>

                    <p>District:</p>
                    <p>Municipality:</p>
                    <p>Ward:</p>
                  </div>
                </div>
                <div className="goal-progress">
                  <p>Goal Progress: 0 out of 6743</p>
                </div>
              </div>
              <div className="provincial-cards"></div>
              <div className="provincial-cards"></div>
              <div className="provincial-cards"></div>
              <div className="provincial-cards"></div>
              <div className="provincial-cards"></div>
              <div className="provincial-cards"></div>
            </div>
          )}
        </>
      )}
     
    </div>
  );
};

export default DemographyWing;
