// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.approach-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
}

.approach-container h1,
.approach-container h4 {
  color: white;
}

.approach-contents-holder {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.approach-contents-div {
  background: rgb(78, 78, 78);
  padding: 0.5rem 0.7rem;
  border-radius: 0.5rem;
}

.approach-contents-div h3 {
  color: white;
  margin-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/ShunyaDestinations/Approach/approach.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;;EAEZ,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,cAAc;AAChB;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,2BAA2B;EAC3B,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":[".approach-container {\n  width: 100%;\n  height: 100%;\n\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n  overflow: auto;\n}\n\n.approach-container h1,\n.approach-container h4 {\n  color: white;\n}\n\n.approach-contents-holder {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.approach-contents-div {\n  background: rgb(78, 78, 78);\n  padding: 0.5rem 0.7rem;\n  border-radius: 0.5rem;\n}\n\n.approach-contents-div h3 {\n  color: white;\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
