const keysNamingAdjuster = {
  firstname: "first name",
  middlename: "middle name",
  lastname: "last name",
  gender: "gender",
  dateOfBirth: "date of birth",
  contact: "contact",
  citizenshipNo: "citizenship no.",
  panNo: "pan no.",
  wardNo: "ward no.",
  toleAndStreet: "tole / street",
};

export const generateInvalidKeysMessageForObject = (object) => {
  const invalidKeys = Object.entries(object)
    .filter(([key, value]) => value === null || value === undefined || value.trim() === "")
    .map(([key]) => keysNamingAdjuster[key] || key);

  if (invalidKeys.length === 0) return false;

  invalidKeys[0] = invalidKeys[0].charAt(0).toUpperCase() + invalidKeys[0].slice(1);

  if (invalidKeys.length === 1) {
    return (
      <>
        <span className="invalid-key">{invalidKeys[0]}</span> value is missing.
        <br />
        <span className="action">Provide the value and try again.</span>
      </>
    );
  }
  if (invalidKeys.length === 2) {
    return (
      <>
        <span className="invalid-key">{invalidKeys[0]}</span> and <span className="invalid-key">{invalidKeys[1]}</span>{" "}
        values are missing.
        <br />
        <span className="action">Provide missing values and try again.</span>
      </>
    );
  }

  if (invalidKeys.length > 2) {
    const lastInvalidKey = invalidKeys.pop();
    const stringForRemainingInvalidKeys = invalidKeys.join(", ");

    return (
      <>
        <span className="invalid-key">{stringForRemainingInvalidKeys}</span> and{" "}
        <span className="invalid-key">{lastInvalidKey}</span> values are missing.
        <br />
        <span className="action">Provide missing values and try again.</span>
      </>
    );
  }
};

export const hasObjectValueChanged = (object1, object2) => {
  const normalizeObject = (obj) => {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key.toLowerCase(), String(value).trim()]));
  };

  const normalizedObject1 = normalizeObject(object1);
  const normalizedObject2 = normalizeObject(object2);

  const keys1 = Object.keys(normalizedObject1);

  // if (keys1.length !== keys2.length) {
  //   return true;
  // }

  for (let key of keys1) {
    if (normalizedObject1[key] !== normalizedObject2[key]) return true;
  }

  return false;
};
