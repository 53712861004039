import React from "react";
import "./shunyaLogin.css";

import { useNavigate } from "react-router-dom";

const ShunyaLogin = () => {
  const navigate = useNavigate();
  return (
    <div className="wizards-revisits">
      <h3>SHUNYA</h3>
      <h1>Login</h1>
      <div className="shunya-login-whisper">
        <p>The portal was somewhere around here...</p>
        <p>May be if I speak of my dissolved name...</p>

        <div className="username-pick">
          <input type="text" />
        </div>

        <p>and cast my spell...</p>
        <div className="access-spell-conjuring">
          <input type="password" />
        </div>
      </div>

      <div className="shunya-login-portal">
        <h3>Welcome To 'SHUNYA'</h3>
        <button onClick={() => navigate("/shunya-realm")}>PROCEED TO SHUNYA</button>
        <button onClick={() => navigate("/signup")}>DISSOLVE ME FIRST</button>
      </div>
    </div>
  );
};

export default ShunyaLogin;
