import { createContext, useContext, useState, useEffect } from "react";
import { axiosProtectedInstance } from "../config/axios/axios";
import { RE_INITIALIZE_SESSION } from "../config/URLs/URLsDefinations/AccessURLs";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosProtectedInstance.get(RE_INITIALIZE_SESSION);
        setUser(response?.data);
      } catch (error) {
        console.log("Auth Error", error);
      } finally {
        setLoading(false);
      }
    };
    if (user === null) fetchUserData();
  }, [user]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, user, setUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
