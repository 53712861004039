import React, { useState } from "react";
import "./citizenshipForm.css";

import { useResponse } from "../../../../context/ResponseContext";

import {
  provinces,
  getDistrictsByProvince,
  getMunicipalitiesByProvinceAndDistrict,
  getWardsByMunicipality,
} from "../../../../dataProvider/AdministrativeDivision";
import { generateInvalidKeysMessageForObject } from "../../../../utils/DataValidators/ObjectValidator";

const CitizenshipForm = () => {
  const { setResponseMessage } = useResponse();

  // this limit, cuts off the exact date as well. if someone reaches 16 today, it doesnt allow today. so need to adjust that 1 day.
  const CitizenshipAgeLimit = new Date(new Date().setFullYear(new Date().getFullYear() - 16))
    .toISOString()
    .split("T")[0];

  const [citizenshipApplicantDetails, setCitizenshipApplicantDetails] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    gender: "",
    dateOfBirth: "",
    contact: "",
    occupation: "",
  });

  const handleCitizenshipApplicantDetailsInput = (event) => {
    const { name, value } = event.target;

    setCitizenshipApplicantDetails((prevValues) => {
      if (name === "dateOfBirth") {
        const dateEntered = new Date(value);
        const ageLimit = new Date(CitizenshipAgeLimit);

        if (dateEntered > ageLimit) {
          setResponseMessage({
            status: "error",
            title: "Minimum Age Not Met",
            message: "You must be at least 16 years old to apply for citizenship.",
          });
          return {
            ...prevValues,
            dateOfBirth: "",
          };
        }
      }
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const addressFields = {
    province: "",
    district: "",
    municipality: "",
    wardNo: "",
  };

  const [temporaryAddress, setTemporaryAddress] = useState(addressFields);
  const [permanentAddress, setPermanentAddress] = useState(addressFields);

  const handleTemporaryAddressInput = (event) => {
    const { name, value } = event.target;
    setTemporaryAddress((prevState) => {
      const updatedAddress = { ...prevState, [name]: value };

      if (name === "province") {
        updatedAddress.district = updatedAddress.municipality = updatedAddress.wardNo = "";
      } else if (name === "district") {
        updatedAddress.municipality = updatedAddress.wardNo = "";
      } else if (name === "municipality") {
        updatedAddress.wardNo = "";
      }
      return updatedAddress;
    });
  };

  const handlePermanentAddressInput = (event) => {
    const { name, value } = event.target;
    setPermanentAddress((prevState) => {
      const updatedAddress = { ...prevState, [name]: value };

      if (name === "province") {
        updatedAddress.district = updatedAddress.municipality = updatedAddress.wardNo = "";
      } else if (name === "district") {
        updatedAddress.municipality = updatedAddress.wardNo = "";
      } else if (name === "municipality") {
        updatedAddress.wardNo = "";
      }
      return updatedAddress;
    });
  };

  const validateApplicantDetails = () => {
    citizenshipApplicantDetails.middlename === "" && delete citizenshipApplicantDetails.middlename;

    const invalidKeysMessageForApplicantDetails = generateInvalidKeysMessageForObject(citizenshipApplicantDetails);
    if (invalidKeysMessageForApplicantDetails) {
      setResponseMessage({
        status: "error",
        title: "Applicantion Is Incomplete.",
        message: invalidKeysMessageForApplicantDetails,
      });
      return false;
    }

    const invalidKeysMessageForTemporaryAddress = generateInvalidKeysMessageForObject(temporaryAddress);
    if (invalidKeysMessageForTemporaryAddress) {
      setResponseMessage({
        status: "error",
        title: "Applicantion Is Incomplete.",
        message: invalidKeysMessageForTemporaryAddress,
      });
      return false;
    }

    const invalidKeysMessageForPermanentAddress = generateInvalidKeysMessageForObject(permanentAddress);
    if (invalidKeysMessageForPermanentAddress) {
      setResponseMessage({
        status: "error",
        title: "Applicantion Is Incomplete.",
        message: invalidKeysMessageForPermanentAddress,
      });
      return false;
    }
    return true;
  };

  const initiateSubmission = () => {
    const areDetailsValid = validateApplicantDetails();
    if (!areDetailsValid) return;
  };

  return (
    <div className="citizenship-form">
      <div className="citizenship-form-content">
        <h2>CITIZEN REGISTRATION</h2>

        <div className="forms-details-section">
          <div className="citizen-identity-details">
            <h3>IDENTITY DETAILS</h3>
            <div className="citizen-identity-provider">
              <input
                type="text"
                name="firstname"
                placeholder="firstname"
                value={citizenshipApplicantDetails.firstname}
                onChange={handleCitizenshipApplicantDetailsInput}
              />
              <input
                type="text"
                name="middlename"
                placeholder="middlename"
                value={citizenshipApplicantDetails.middlename}
                onChange={handleCitizenshipApplicantDetailsInput}
              />
              <input
                type="text"
                name="lastname"
                placeholder="lastname"
                value={citizenshipApplicantDetails.lastname}
                onChange={handleCitizenshipApplicantDetailsInput}
              />
              <select
                name="gender"
                value={citizenshipApplicantDetails.gender}
                onChange={handleCitizenshipApplicantDetailsInput}
              >
                <option value="" disabled>
                  Select Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
              <input
                type="date"
                placeholder="date of birth (A.D)"
                name="dateOfBirth"
                value={citizenshipApplicantDetails.dateOfBirth}
                onChange={handleCitizenshipApplicantDetailsInput}
              />
              <input
                type="text"
                name="contact"
                placeholder="contact"
                value={citizenshipApplicantDetails.contact}
                onChange={handleCitizenshipApplicantDetailsInput}
              />
              <input
                type="text"
                name="occupation"
                placeholder="occupation"
                value={citizenshipApplicantDetails.occupation}
                onChange={handleCitizenshipApplicantDetailsInput}
              />
            </div>
            <p>Please use A.D Date as we dont have Nepali Date support yet.</p>
            <p>
              Please provide your correct occupation as this will be basis for selecting individuals for any role that
              we will introduce in the second phase.
            </p>
          </div>

          <div className="citizen-identity-details">
            <h3>FAMILY DETAILS</h3>
            <p>
              As the main goal is to reach to every-ward, and we may be resetting database after that, we wont ask these
              details from you for now.
            </p>
          </div>

          <div className="citizen-address-details">
            <h3>ADDRESS DETAILS</h3>
            <div className="address-details-flex">
              <div className="temporary-address-details">
                <h4>Temporary Address</h4>
                <div className="citizen-address-selectors">
                  {/* temporary province selection */}

                  <select name="province" value={temporaryAddress.province} onChange={handleTemporaryAddressInput}>
                    <option>Select a province</option>
                    {provinces.map((province, index) => (
                      <option key={index} value={province}>
                        {province}
                      </option>
                    ))}
                  </select>

                  {/* temporary district selection */}

                  <select name="district" value={temporaryAddress.district} onChange={handleTemporaryAddressInput}>
                    <option>Select a district</option>
                    {temporaryAddress.province &&
                      getDistrictsByProvince(temporaryAddress.province).map((district, index) => (
                        <option key={index} value={district}>
                          {district}
                        </option>
                      ))}
                  </select>

                  {/* temporary municipality selection */}

                  <select
                    name="municipality"
                    value={temporaryAddress.municipality}
                    onChange={handleTemporaryAddressInput}
                  >
                    <option>Select a municipality</option>
                    {temporaryAddress.province &&
                      temporaryAddress.district &&
                      getMunicipalitiesByProvinceAndDistrict(temporaryAddress.province, temporaryAddress.district).map(
                        (municipality, index) => (
                          <option key={index} value={municipality}>
                            {municipality}
                          </option>
                        )
                      )}
                  </select>

                  {/* temporary ward no selection */}

                  <select name="wardNo" value={temporaryAddress.wardNo} onChange={handleTemporaryAddressInput}>
                    <option>Ward No</option>
                    {temporaryAddress.province &&
                      temporaryAddress.district &&
                      temporaryAddress.municipality &&
                      getWardsByMunicipality(
                        temporaryAddress.province,
                        temporaryAddress.district,
                        temporaryAddress.municipality
                      ).map((ward, index) => (
                        <option key={index} value={ward}>
                          {ward}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="permanent-address-details">
                <h4>Permanent Address</h4>
                <div className="citizen-address-selectors">
                  {/* permanent province selection */}

                  <select name="province" value={permanentAddress.province} onChange={handlePermanentAddressInput}>
                    <option>Select a province</option>
                    {provinces.map((province, index) => (
                      <option key={index} value={province}>
                        {province}
                      </option>
                    ))}
                  </select>

                  {/* permanent district selection */}

                  <select name="district" value={permanentAddress.district} onChange={handlePermanentAddressInput}>
                    <option>Select a district</option>
                    {permanentAddress.province &&
                      getDistrictsByProvince(permanentAddress.province).map((district, index) => (
                        <option key={index} value={district}>
                          {district}
                        </option>
                      ))}
                  </select>

                  {/* permanent municipality selection */}

                  <select
                    name="municipality"
                    value={permanentAddress.municipality}
                    onChange={handlePermanentAddressInput}
                  >
                    <option>Select a municipality</option>
                    {permanentAddress.province &&
                      permanentAddress.district &&
                      getMunicipalitiesByProvinceAndDistrict(permanentAddress.province, permanentAddress.district).map(
                        (municipality, index) => (
                          <option key={index} value={municipality}>
                            {municipality}
                          </option>
                        )
                      )}
                  </select>

                  {/* permanent ward no selection */}

                  <select name="wardNo" value={permanentAddress.wardNo} onChange={handlePermanentAddressInput}>
                    <option>Ward No</option>
                    {permanentAddress.province &&
                      permanentAddress.district &&
                      permanentAddress.municipality &&
                      getWardsByMunicipality(
                        permanentAddress.province,
                        permanentAddress.district,
                        permanentAddress.municipality
                      ).map((ward, index) => (
                        <option key={index} value={ward}>
                          {ward}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <button className="citizenship-application-button" onClick={initiateSubmission}>
            SUBMIT APPLICATION
          </button>
        </div>
      </div>
    </div>
  );
};

export default CitizenshipForm;
