import React from "react";
import "./loginSignup.css";

const Signup = () => {
  return (
    <div className="access-pages-wrapper">
      <div className="access-type-title">
        <h1>Sign Up</h1>
        <p>If you have decided so then, THANKS!!</p>
      </div>

      <div className="access-input-contents">
        <p>The portal awaits for your dissolution to begin.</p>
        <div className="access-input-envelope">
          <input type="email" name="email" />
        </div>
        <div className="access-input-envelope">
          <input type="text" name="username" />
        </div>
        <div className="access-input-envelope">
          <input type="password" name="password" />
        </div>
        <div className="access-proceed-or-switch">
          <button>DISSOLVE ME</button>
          <button>I HAVE ALREADY DISSOLVED</button>
        </div>
        <p>Please don't mind the fantasy tone of my writings.</p>
      </div>
    </div>
  );
};

export default Signup;
