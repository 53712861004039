// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wizards-registration {
  max-width: 35rem;
  width: 100%;
  height: 100%;

  position: relative;
  box-sizing: border-box;
  padding: 0 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  transition: all 0.5s ease;
  overflow: auto;
}

.wizards-registration h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.wizards-registration h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.wizards-registration p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.wizards-registration input {
  width: 90%;
  height: 4rem;

  border: none;
  outline: none;
  border-radius: 2rem;

  padding: 0 2rem;
  line-height: 2rem;
  font-size: 1.2rem;
}

.wizards-registration button {
  width: 90%;
  height: 4rem;

  border: none;
  outline: none;
  border-radius: 2rem;
  margin-bottom: 1rem;

  background: black;
  color: white;
}

.email-address,
.username-pick,
.access-spell-conjuring {
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  margin: 1rem 0;
  border-radius: 0.5rem;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/ShunyaSignup/shunyaSignup.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;;EAEZ,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;;EAEf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,6BAA6B;;EAE7B,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,YAAY;;EAEZ,YAAY;EACZ,aAAa;EACb,mBAAmB;;EAEnB,eAAe;EACf,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,YAAY;;EAEZ,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;;EAEnB,iBAAiB;EACjB,YAAY;AACd;;AAEA;;;EAGE,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,qBAAqB;EACrB,YAAY;;EAEZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".wizards-registration {\n  max-width: 35rem;\n  width: 100%;\n  height: 100%;\n\n  position: relative;\n  box-sizing: border-box;\n  padding: 0 1rem;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-evenly;\n\n  transition: all 0.5s ease;\n  overflow: auto;\n}\n\n.wizards-registration h3 {\n  font-size: 2rem;\n  margin-bottom: 1rem;\n}\n\n.wizards-registration h1 {\n  font-size: 3rem;\n  margin-bottom: 2rem;\n}\n\n.wizards-registration p {\n  font-size: 1rem;\n  margin-bottom: 0.5rem;\n}\n\n.wizards-registration input {\n  width: 90%;\n  height: 4rem;\n\n  border: none;\n  outline: none;\n  border-radius: 2rem;\n\n  padding: 0 2rem;\n  line-height: 2rem;\n  font-size: 1.2rem;\n}\n\n.wizards-registration button {\n  width: 90%;\n  height: 4rem;\n\n  border: none;\n  outline: none;\n  border-radius: 2rem;\n  margin-bottom: 1rem;\n\n  background: black;\n  color: white;\n}\n\n.email-address,\n.username-pick,\n.access-spell-conjuring {\n  width: 100%;\n  text-align: center;\n  padding: 0.5rem;\n  margin: 1rem 0;\n  border-radius: 0.5rem;\n  flex-grow: 1;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
