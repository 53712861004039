import React from "react";
import "./demographyDocumentation.css";

const DemographyDocumentation = () => {
  return (
    <div className="demography-documentation-container">
      <h2>DOCUMENTATION</h2>
      <p>Note: These texts are all my personal approach and is subjected to change to fit the actual needs.</p>

      <div className="documentation-containers">
        <h3>RESPONSIBILITIES</h3>
      </div>
    </div>
  );
};

export default DemographyDocumentation;
