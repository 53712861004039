import React from "react";
import "./shunya.css";

import { NavLink, Routes, Route } from "react-router-dom";

import { IconFriends } from "@tabler/icons-react";
import { IconDashboard } from "@tabler/icons-react";
import { IconShieldHalf } from "@tabler/icons-react";
import { IconGavel } from "@tabler/icons-react";

import Approach from "../ShunyaDestinations/Approach/Approach";
import DemographyWing from "../ShunyaDestinations/DemographyWing/DemographyWing";
import InternalSecurityWing from "../ShunyaDestinations/InternalSecutiryWing/InternalSecurityWing";
import JudiciaryWing from "../ShunyaDestinations/JudiciaryWing/JudiciaryWing";

const ShunyaRealm = () => {
  return (
    <div className="shunya-realm">
      <div className="shunya-top">This is our heading and potentially will have some navigations as well may be. We will decide later on. </div>
      <div className="shunya-content">
        <div className="shunya-nav">
          <div className="shunya-sidebar">
            <NavLink to="approach" className={({ isActive }) => `department ${isActive ? "active-department" : ""}`}>
              <IconDashboard />
              <h3>Approach</h3>
            </NavLink>
            <NavLink to="demography" className={({ isActive }) => `department ${isActive ? "active-department" : ""}`}>
              <IconFriends />
              <h3>Demography Wing</h3>
            </NavLink>
            <NavLink to="security" className={({ isActive }) => `department ${isActive ? "active-department" : ""}`}>
              <IconShieldHalf />
              <h3>Internal Security Wing</h3>
            </NavLink>
            <NavLink to="judiciary" className={({ isActive }) => `department ${isActive ? "active-department" : ""}`}>
              <IconGavel />
              <h3>Judiciary Wing</h3>
            </NavLink>
          </div>
        </div>
        <div className="shunya-destination">
          <Routes>
            <Route path="/approach" element={<Approach />} />
            <Route path="/demography" element={<DemographyWing />} />
            <Route path="/security" element={<InternalSecurityWing />} />
            <Route path="/judiciary" element={<JudiciaryWing />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default ShunyaRealm;
