import React from "react";
import "./approach.css";

const Approach = () => {
  return (
    <div className="approach-container">
      <h1>Approach</h1>
      <h4>
        Please read all the sections to have proper understanding of things. Or else you won't be able to contribute in
        a positive way.
      </h4>

      <div className="approach-contents-holder">
        <div className="approach-contents-div">
          <h3>WE NEED SOME EXPLANATIONS</h3>
          <div className="appraoch-section-content">
            <p>
              Okay, then either on sidebar if you are on a desktop or on the bottom of screen if you are on mobile, you
              are seeing some icons and and some texts (not on mobile).
            </p>
            <p>These are supposed to what we currently know as 'Ministries'.</p>
            <br />
            <p>
              Reason why I gave them the 'Wing' thing. Its because, you see the current leaders, I think they
              misunderstands things when they gain the title 'Minister'. I hope, I won't be pulled into some legal
              issues because of this statement.
            </p>
            <p>
              They feel that the power they gain, is their personal achievement and not something bestowed upon them for
              their reigning period.
            </p>
            <p>
              So lets not call them 'Minister' or 'Mantri Jui' anymore. They will simply be called 'Wing Chief'. I know
              the title change wont make much difference, but atleast we may get into their head, you are not the so
              called powerful 'Minister' anymore, you are simply 'Wing Chief' appointed for the purpose of handling the
              Wing. Also, let me tell you this here, None of the positions title or departments name are fixed. They are
              just my personal choices, which can be changed if we can get something more meaningful names to them. Even
              'SHUNYA' can have a new name. But that again depends who is the current head of 'SHUNYA' at any time. Or
              is this wrong, being able to change the name itself of the main govening entity might give the position
              holder wrong information of that they are 'Supreme' right? We can decide that as well. But the thing is
              nothing here is fixed for now, until we collectively agree when we achieve certain milestone.
            </p>
          </div>
        </div>

        <div className="approach-contents-div">
          <h3>HOW DOES THIS WORK?</h3>
          <p>
            As said earlier, we will first design our 'Wings'. One at a time. Or if we can get more team members we can
            handle multiple as well. I have chosen to move ahead with 'Demography Wing' first. Why because, again
            country-men. And also, we will need to play out the roles as well.
          </p>
          <br />
          <p>
            Each Wing, will have a documentation about it as well, just beside the Wing Heading. This documentation will
            include details of process. Like for example in 'Demography Wing' since, we will be getting our members
            details, we will have documentation on how can one apply. And how the process will flow. I might not be
            clear, I can understand that, but I am too excited and this is causing me to rush things abit. But again,
            since we said we will be coming back and re-edit things, we can always make these clear and conside as the
            need demands of it. For now, I am moving ahead with just the bare minimum just so I can reach you all and
            then make changes as per your suggestions.
          </p>
          <br />
          <p>
            Also I plan to write the constitution in our own way as well. That is when we find few lawyers and judges.
            Each 'Wing' will write its separate constitution and we can merge them all to get one.
          </p>
        </div>
      </div>

      <div className="approach-contents-div">
        <h3>WHAT IS OUR ROLE FOR NOW THEN?</h3>
        <p>
          I am not sure, how should I organize this. But I can get your help. For now, on this initial phase, I want
          everyone who arrives here, to go to 'Demography Wing' and provide their identity details as is asked.
        </p>
        <p>I am sure most you might not still trust the website, so I wont ask for full details of yours.</p>
        <p>Only details you need to be providing is:</p>
        <li>Fullname</li>
        <li>Date of Birth</li>
        <li>Gender</li>
        <li>Contact No.</li>
        <li>Occupation</li>
        <li>Address: Both temporary and permanent.</li>
      </div>
      <div className="approach-contents-div">
        <h2>Primary Goal</h2>
        <p>To get atleast 1 country-men from each ward.</p>

        <h3>Secondary Goal</h3>
        <p>
          Find a proper addressing format. By proper I mean, the address that could allow someone to be able to knock on
          my room door. Now, I know that's is not viable for security reasons. But we will not share this information
          randomly to anyone. The address is for record purpose in our 'Demography Wing'. 'Demography Wing' will see to
          it that our full address is never disclosed. It will only allow to view someone's profile until the Ward
          level. The tole name, street name, house number and room/flat number will all be confidential. 'Demography
          Wing' will only share this in the case of 'Internal Security Wing' or any other case which will have its
          actual need for. Further details of 'Demography Wing' will be included in its own documentation.
        </p>
        <p>
          I want our address to be as: Room/Flat No, House/Apartment/Building No, Street Name, Tole Name, Ward No, City
          Name (I dont know, how this works), Municipality Name, District Name, Province Name, Nepal
        </p>
      </div>
      <h1>Well, if you are ready then, click on the 'Demography Wing'.</h1>
    </div>
  );
};

export default Approach;
