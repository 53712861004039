import React, { useState } from "react";
import "./dissolutionPortal.css";

import { useNavigate } from "react-router-dom";
import useShunyaInitiation from "../../config/URLs/URLCallHooks/useShunyaInitiation";

const DissolutionPortal = () => {
  const navigate = useNavigate();
  const { dissolveIntoShunya } = useShunyaInitiation();

  const [consequencesOath, setConsequencesOath] = useState(false);
  const [freewillOath, setFreewillOath] = useState(false);
  const [shunyaSpell, setShunyaSpell] = useState("");

  const initiateDissolution = async () => {
    const response = await dissolveIntoShunya(shunyaSpell);
    console.log("Response", response);
    if (response) navigate("/initiation");
    else return;
  };

  return (
    <div className="portal-wrapper">
      <h1>SHUNYA</h1>
      <div className="portal-intro">
        <p>Someone, whispered this place to you?</p>
        <p>We will know about that shortly, if you have been invited or not.</p>
      </div>

      <div className="portal-danger">
        <h2>DANGER AHEAD</h2>
        <p>
          Okay, I will tell you something about this place, invited or not, I must let you know what you are daring to
          enter about.
        </p>

        <p>
          Its, just another website, however, as it is being designed by a novoice developer, 'Spectre' is my name by
          the way.
        </p>
        <p>
          So right, back to topic, as it is being developed by a novice, the website may lack the data security. That
          means hackers may be able to get to sensitive data related to you from this portal.
        </p>
        <p>
          So in-case, you dont want that happening, I suggest to return back to where you come from and forget that you
          ever came across this place. This goes to anyone who is here, whether you were invited or you just randomly
          appeared here.
        </p>
        <p>
          If you proceed ahead know that neither the creator of this website, nor the website itself or the one who
          invited you here can be held accountable to what befalls upon you.
        </p>
      </div>

      <div className="portal-agreement">
        <p>If you are still willing to see whats this about, then you must seal yourself with the following oaths</p>

        <span className="portal-oaths">
          <input
            type="checkbox"
            checked={consequencesOath}
            onChange={(event) => setConsequencesOath(event.target.value)}
          />
          <p>
            I take this oath to not hold anyone else as responsible entity but myself for whatever consequences comes
            from using this website.
          </p>
        </span>
        <span className="portal-oaths">
          <input type="checkbox" checked={freewillOath} onChange={(event) => setFreewillOath(event.target.value)} />
          <p>
            I acknowledge that I was invited here, but entering the portal is my free choice. I am not pressured in any
            manner to be a part of this except by my own free will.
          </p>
        </span>
      </div>
      <div className="portal-validation">
        <p>
          If you have decided to traverse this realm, even though you have been warned about not to do so, cast on the
          spell that opens the door of this portal that was whispered to you.
        </p>
        <input type="password" value={shunyaSpell} onChange={(event) => setShunyaSpell(event.target.value)} />

        <button
          disabled={!consequencesOath || !freewillOath || shunyaSpell.trim() === ""}
          onClick={initiateDissolution}
        >
          DISSOLVE INTO SHUNYA
        </button>
      </div>
    </div>
  );
};

export default DissolutionPortal;
