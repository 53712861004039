import React from "react";
import "./landing.css";

import Signup from "../AccessPages/Signup";
import Login from "../AccessPages/Login";

const LandingPage = () => {
  return (
    <div className="landing-wrapper">
      <div className="site-hazard">
        <h1 className="danger-text">IMPORTANT</h1>
        <p className="danger-text">Please read before you proceed.</p>
        <div className="declaration">
          <p>
            There are no terms and conditions of use, neither are there any policies or any consent form for cookies
            approval.
          </p>
          <p>
            The following paragraphs will be considered the official Terms & Conditions and Policies until a proper
            Terms & Conditions and Policies are prepared in future.
          </p>
          <p className="danger-text">Read all the lines carefully before you decide the use of this website.</p>
          <p className="danger-text">
            You need to have your mouse on this section for the scroll to work if you are on a desktop or laptop or any
            device whose scrolling dependends on devices similar to mouse.
          </p>

          <div className="declaration-bullet-points">
            <p>1. The website is currently not registered with the Government of Nepal.</p>

            <p>
              2. SHUNYA and its creator hereby declare that they bear no responsibility for any consequences arising
              from the use of this website.
            </p>
            <p>
              3. By proceeding, you acknowledge that you have been informed of potential risks associated with using
              this website. While the specific risks cannot be listed or identified, the term 'risks' encompasses any
              incident where one party is either 'SHUNYA' or its creator and the other party is you, the user.
            </p>
            <p>
              4. By continuing, you confirm that you are making a deliberate choice to use this website and agree not to
              hold the website or its creator responsible for any unfortunate events that may occur.
            </p>
            <p>
              5. The website and its creator makes no attempt to present the platform as an enigmatic portal to
              encourage participation. Therefore, no individual may justify their involvement with statements implying
              curiosity as the reason for their engagement. If curiosity is your sole motivation, neither 'SHUNYA' nor
              its creator wish to be associated with your decision.
            </p>
            <p>
              6. This website uses cookies, which will be stored in your browser. These cookies are solely intended to
              enhance the user interface and functionality of the website. The website does not offer, nor does it plan
              to develop, a version that operates without cookies. By proceeding, you acknowledge and allow the use of
              cookies as necessary for the website's operation.
            </p>
            <p>
              7. The above conditions are subject to frequent changes. Users of the website will be notified of any
              updates through methods deemed appropriate. Failure to review such notifications will be the sole
              responsibility of the user, and the website and its creator will not be held accountable for any
              consequences arising from the changes.
            </p>

            <p>
              8. This is an experimental project aimed at developing a platform that empowers citizens, including
              myself, to actively participate in efforts that drive positive change within the country.
            </p>
            <p>
              9. And in no means I and 'SHUNYA' are trying to mock the country's current platforms. If anything, we are
              just focused on finding a better platform that will being smoothness and transparency without our
              Governing System.
            </p>
            <p>
              10. In case, I have missed out on some important factors that can be of a legal issue for "Me" or
              "SHUNYA", we keep the right to have the 'Last Word', provided that we are still under lawful act.
            </p>
          </div>
        </div>
      </div>
      <div className="site-goal">
        <h2>SHUNYA</h2>
        <div className="goal-heading">
          <h2>GOAL</h2>
          <h1>DEVELOP NEPAL'S CENTRAL PLATFORM FOR GOVENANCE AND SERVICES</h1>
        </div>

        <h2>27th JAN 2025</h2>
      </div>

      <div className="login-signup">
        <Signup />
      </div>
    </div>
  );
};

export default LandingPage;
