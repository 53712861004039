// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demography-documentation-container {
  width: 100%;
  height: auto;
  flex-grow: 1;

  background: gray;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.demography-documentation-container h2 {
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/ShunyaDestinations/DemographyWing/demographyDocumentation.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;;EAEZ,gBAAgB;EAChB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".demography-documentation-container {\n  width: 100%;\n  height: auto;\n  flex-grow: 1;\n\n  background: gray;\n  padding: 0.5rem;\n  border-radius: 0.5rem;\n}\n\n.demography-documentation-container h2 {\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
