// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demography-documentation-container {
  width: 100%;
  height: auto;
  flex-grow: 1;

  background: gray;
  padding: 0.5rem;
  border-radius: 0.5rem;
  overflow: auto;
}

.demography-documentation-container h2 {
  color: white;
}

.documentation-containers {
  background: gray;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.documentation-containers h3 {
  color: white;
  margin-top: 1rem;
}

.paragraph-div {
  background: gray;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/ShunyaDestinations/DemographyWing/demographyDocumentation.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;;EAEZ,gBAAgB;EAChB,eAAe;EACf,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb","sourcesContent":[".demography-documentation-container {\n  width: 100%;\n  height: auto;\n  flex-grow: 1;\n\n  background: gray;\n  padding: 0.5rem;\n  border-radius: 0.5rem;\n  overflow: auto;\n}\n\n.demography-documentation-container h2 {\n  color: white;\n}\n\n.documentation-containers {\n  background: gray;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.documentation-containers h3 {\n  color: white;\n  margin-top: 1rem;\n}\n\n.paragraph-div {\n  background: gray;\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
