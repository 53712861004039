import React from "react";
import "./shunyaInitiation.css";

import { useNavigate } from "react-router-dom";

const ShunyaInitiation = () => {
  const navigate = useNavigate();
  return (
    <div className="initiation-wrapper">
      <h1>SHUNYA</h1>
      <div className="initiation-intro">
        <p>So you decided to take the risk and be here...</p>
        <p>Commendable Valor, you show...</p>
      </div>
      <div className="initiation-description">
        <p>Okay, then I will tell you more about why I am trying to develop this website.</p>
        <p>I want to centralize things related to governing of our country. Not just things, but everything.</p>
        <p>Okay, I understand. I don't have a chance to complete this even within my whole lifespan.</p>
        <p>But that is alright. I am happy doing the coding thing. I will code till I can and see where I can reach.</p>
        <p>Now there will be ups and down in this journey, but again that is fine.</p>
      </div>

      <div className="initiation-approach">
        <p>Why brag about it to us? Just do your thing in silence and if you complete it then present it before us.</p>
        <p>
          Yeah, that is probably how I should have proceeded with this. But for some reason, I think that approach would
          not be fitting for this project.
        </p>
        <p>
          After all, a country is not defined just by a map that is printed on some piece of paper. I believe, a country
          exists because of country-men who agrees to share the common identity as the fellow country-men.
        </p>
        <p>I dont know if that makes any sense at all... But whatever...</p>
        <p>Point was to make you all clear what this website is all about and will be all about.</p>
      </div>

      <div className="initiation-support">
        <p>Now since, I have decided to go with this approach, I have thought how you all could be of help.</p>
        <p>It's simple, you tell me how things should be.</p>
        <p>And I will try to see if I can make that.</p>
        <p>Don't stress much about what you could tell me about.</p>
        <p>It will come to us.</p>
        <p>
          I myself dont have the slightest of the idea of what I am supposed to make, but as I try things out we can all
          see if that works, if it doesn't we will change things again and see if that works, again and again...
        </p>
        <p>
          Also, I am pretty sure, I alone cannot finish this, so I have thought of how I could get help from other
          fellow coders as well. I am sure once we manage to have a team, we can get this done.
        </p>
        <p>
          But you are not the coder... I understand. You are still valuable. After all, who will handle the post of a
          'Ward Incharge' or a 'District Incharge' or who am I suppose to appoint as the 'Head of Demography' and most
          importantly, who can play the role of 'Country-Men' without whom a nation ceases to exist.
        </p>
        <p>Thats all I have to say for now. </p>
      </div>

      <div className="initiation-final-warining">
        <p>
          Now I remember telling you all that the site is not safe. But I don't think its not that unsafe as well. I
          mean even facebook and google can be hacked if someone decides to do it right.
        </p>
        <p>
          Just saying that, Internet can never be 100% safe. And even though this site can be hacked, I believe that its
          not so easy as to click a button. I say this because, this site communication happens over HTTPS connection,
          which is what most of the websites communicates with. And I myself dont understand what is that, all I know is
          that the browser doesn't warn you with 'the site is not secure' right. You would know what I mean if you have
          ever came accross such sites that the browser warns you with. If you want to do some searching thing you can
          google for 'HTTPS VS HTTP'.
        </p>
        <p>
          Also, down the future, we will get someone in our security department as well, and may be then we can say the
          site is really safe. Until then, this is what we will have. So its really your decision if you would still
          want to proceed.
        </p>
        <p>
          Also, if you proceed it doesn't mean that you must give all the information that this websites asks for you.
          No don't ever do that. You need to decide on what consequences you would run into if that information gets in
          the wrong hand. And only if you find that the damage wont be of serious consequences, you provide the
          infomation.
        </p>
      </div>
      <div className="initiation-portal">
        <p>
          That was a long read I guess... And if you have decided not to be a part, thats alright. Thank you for your
          time.
        </p>
        <p>Before you go, do me a favor. Dont tell anyone about this.</p>
        <p>Let the ones who are the part of this project decide on how we want to reach out.</p>
      </div>

      <div className="dissolution-cookie-notice">
        <p>
          Now for the ones here, please take note that the website will be using cookies, for the purpose of maintaining
          the websites UI, persisting user-login session. In future if we use cookies for any other purpose you will be
          informed again about the change. If you dont like the website storing cookies in your device, I am to request
          you to leave again, because the site wont function without those. So proceeding further means you agree to let
          the website store cookies in your devices as needed.
        </p>
      </div>

      <div className="dissolution-initiation">
        <button onClick={() => navigate("/signup")}>INITIATE DISSOLUTION</button>
        <button onClick={() => navigate("/login")}>I HAVE ALREADY BEEN DISSOLVED</button>
      </div>
    </div>
  );
};

export default ShunyaInitiation;
