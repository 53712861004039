// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.access-pages-wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.access-type-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.access-input-contents {
  width: 100%;
  height: 60%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}

.access-input-envelope {
  width: 100%;
  padding: 0 1rem;

  position: relative;
  display: flex;
  justify-content: center;
}

.access-input-envelope input {
  width: 100%;
  height: 3rem;

  border: 1px solid var(--emerald-green);
  border-radius: 2rem;
  padding-left: 4rem;

  font-size: 1.2rem;
  line-height: 1.2rem;
}

.access-proceed-or-switch {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 42rem) {
  .access-pages-wrapper {
    width: 100%;
    height: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/AccessPages/loginSignup.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;;EAEZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,WAAW;;EAEX,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,eAAe;;EAEf,kBAAkB;EAClB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;;EAEZ,sCAAsC;EACtC,mBAAmB;EACnB,kBAAkB;;EAElB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".access-pages-wrapper {\n  width: 100%;\n  height: 100%;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.access-type-title {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.access-input-contents {\n  width: 100%;\n  height: 60%;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  align-items: center;\n  gap: 1rem;\n}\n\n.access-input-envelope {\n  width: 100%;\n  padding: 0 1rem;\n\n  position: relative;\n  display: flex;\n  justify-content: center;\n}\n\n.access-input-envelope input {\n  width: 100%;\n  height: 3rem;\n\n  border: 1px solid var(--emerald-green);\n  border-radius: 2rem;\n  padding-left: 4rem;\n\n  font-size: 1.2rem;\n  line-height: 1.2rem;\n}\n\n.access-proceed-or-switch {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n@media (max-width: 42rem) {\n  .access-pages-wrapper {\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
