import React, { createContext, useState, useEffect, useContext } from "react";
import { useAuth } from "./AuthContext";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const { user } = useAuth();
  const [darkTheme, setDarkTheme] = useState(false);

  const toggleTheme = () => setDarkTheme((prevState) => !prevState);

  useEffect(() => {
    if (user && typeof user.darkTheme === "boolean") {
      setDarkTheme(user.darkTheme);
    }
  }, [user]);

  useEffect(() => {
    if (darkTheme) {
      document.documentElement.classList.add("dark-mode");
    } else {
      document.documentElement.classList.remove("dark-mode");
    }
  }, [darkTheme]);

  return <ThemeContext.Provider value={{ darkTheme, toggleTheme }}>{children}</ThemeContext.Provider>;
};
