import React, { useState } from "react";
import "./demographyWing.css";

import DemographyDocumentation from "./DemographyDocumentation";

const DemographyWing = () => {
  const [openDemographyDocumentation, setOpenDemographyDocumentation] = useState(true);
  const toggleDocumentationView = () => {
    setOpenDemographyDocumentation((prevState) => !prevState);
  };
  return (
    <div className="wing-container">
      <div className="wing-title">
        <h1>Demography Wing</h1>
        <button onClick={toggleDocumentationView}>
          {openDemographyDocumentation ? "CLOSE DOCUMENTATION" : "OPEN DOCUMENTATION"}
        </button>
      </div>
      {openDemographyDocumentation ? (
        <DemographyDocumentation />
      ) : (
        <>
          <div className="wing-services">
            <h2>SERVICES</h2>
            <div className="services-list">
              <button>APPLY FOR CITIZENSHIP</button>
            </div>
          </div>
          <div className="wing-content">
            <div className="provincial-cards"></div>
            <div className="provincial-cards"></div>
            <div className="provincial-cards"></div>
            <div className="provincial-cards"></div>
            <div className="provincial-cards"></div>
            <div className="provincial-cards"></div>
            <div className="provincial-cards"></div>
          </div>
        </>
      )}
    </div>
  );
};

export default DemographyWing;
