import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ResponseDialog from "./components/ResponseDialog/ResponseDialog";

import PersistProtectedRoutes from "./Layouts/PersistProtectedRoutes";
// import OTPVerifier from "./Layouts/HomeLayout/Subcomponents/HomeNavbar/Subcomponents/OTPVerifier/OTPVerifier";

import DissolutionPortal from "./Pages/DissolutionPortal/DissolutionPortal";
import ShunyaInitiation from "./Pages/ShunyaInitiation/ShunyaInitiation";
import ShunyaSignup from "./Pages/ShunyaSignup/ShunyaSignup";
import ShunyaLogin from "./Pages/ShunyaLogin/ShunyaLogin";

import ShunyaRealm from "./Pages/ShunyaRealm/ShunyaRealm";

function App() {
  return (
    <div className="app-container">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Shunya />} /> */}
          <Route path="/" element={<DissolutionPortal />} />
          <Route path="/initiation" element={<ShunyaInitiation />} />
          <Route path="/signup" element={<ShunyaSignup />} />
          <Route path="/login" element={<ShunyaLogin />} />
          <Route path="/shunya-realm/*" element={<ShunyaRealm />} />

          {/* <Route path="/" element={<HomeLayout />} /> */}
          {/* <Route path="/otp-verification" element={<OTPVerifier />} /> */}
          {/* <Route path="/shunya" element={<Shunya />} /> */}

          {/* Protected Routes */}
          {/* <Route element={<PersistProtectedRoutes />}>
            <Route path="/*" element={<ClientLayout />} />
          </Route> */}
        </Routes>
      </BrowserRouter>
      <ResponseDialog />
    </div>
  );
}

export default App;
